:root {
    --over-lay: #509e5033;
    --deeper-lay: #2d6a2d33;
    --icon-color: #7dbd7ac4;
}

.contact {
    list-style-type: none;
}

.contact .list-group-item {
    border: 0;
}

.left_bar {
    color: #030f4c;
}

.navbar-nav li,
.navbar-nav li a {
    color: white;
}

.section {
    margin-top: 20px;
}

.left_bar .list-group-item {
    border: 0;
    border-bottom: 0.3px solid #cbcbcb;
}

.list-group-item a:hover {
    text-decoration: none;
}

.card-image-top {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.section {
    margin-top: 150px;
    position: inherit;
}

.duty strong {
    padding-left: 15px;
    padding-right: 15px;
}

.porfolio_li {
    font-size: 20px;
}

.p_style {
    font-family: math;
}

.hover_img {
    opacity: 0;
    /* display: none; */
}

.porfolio_li li {
    list-style-type: none;
}

.porfolio_li li a {
    color: #d06646;
}

.porfolio_li li a:hover {
    color: #805d52;
    text-decoration: none;
}

.card {
    padding-bottom: 20px;
}

.card-title a:hover .hover_img {
    opacity: 1;
    /* display: inline-block; */
}

.card-title a {
    list-style-type: none;
}

.card-title small {
    text-align: left;
}

.card-title {
    /* height: 160px; */
}

.img_thumb {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-left: 25%;
}

.card_list:first-letter {
    text-transform: capitalize !important;
}

.card_list {
    margin-left: 10px;
    text-align: left;
    display: block;
    padding: 0.5em 0;
    /* word-break: break-all; */
    overflow-wrap: break-word;
}

.keyword_list {
    margin-left: 10px;
    text-align: left;
    /* word-break: break-all; */
    overflow-wrap: break-word;
}

.col-lg-4,
.col-sm-12,
.col-md-6 {
    margin-bottom: 1rem;
}

.card_back {
    display: block;
    box-sizing: border-box;
    transform: rotateY(180deg);
    background-color: blue;
}

.card_front,
.card_back {
    /* position: absolute; */
    /* width: 100%;
    height: 100%; */
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden;
}

.flip_container {
    position: relative;
    perspective: 1000 !important;
    transform-style: preserve-3d;
    transition: all 1.0s linear;
    z-index: 1;
}

.flip_container:hover .card_front {
    transform: rotateY(180deg);
    box-shadow: -5px 5px 5px #aaa;
}

.f1_container {
    position: relative;
    width: 100%;
    /* margin: 10px auto;
    width: 450px;
    height: 281px; */
    z-index: 1;
    min-height: 500px;
}

.f1_container {
    perspective: 1000;
}

.f1_card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1.0s linear;
}

.f1_container:hover .f1_card {
    transform: rotateY(180deg);
    box-shadow: -5px 5px 5px #aaa;
}

.face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.face.back {
    display: block;
    transform: rotateY(180deg);
    box-sizing: border-box;
    padding: 10px;
    color: white;
    text-align: center;
    background-color: #88abf9c9;
}

.container {
    margin-top: 20px;
}

.bg-info {
    background-color: #2a8ed4e3 !important;
}

.portfolio_img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.f1_card p {
    text-align: left;
}

.duty_pic {
    width: 100%;
    height: 460px;
    object-fit: cover;
    margin-top: 20px;
    border-radius: 5px;
}

.duty_exp {
    text-align: left;
    font-size: fangsong;
}

.card_img {
    height: 280px;
    object-fit: cover;
}

.bg-success {
    background-color: #3fb388 !important;
}

.tx-left {
    text-align: left;
}

.key_word {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

.card-title a {
    color: #343a3f;
}

.btn-outline-primary {
    background-color: #4CAF50;
    border-color: #4CAF50;
    color: white;
}

.btn-outline-primary:hover {
    color: #343a3f;
    border-color: #343a3f;
    background-color: white;
}

.section {
    margin-bottom: 50px;
}

.font_icon {
    color: #343a3f;
    /* margin-left: 10px; */
}

.left_logo {
    text-align: right;
}

.right_info {
    text-align: left;
}

.info_list {
    display: inline-block;
    width: 100%;
}

.mg-top {
    margin-top: 20px;
}

.fix-icons {
    position: fixed;
    top: 30%;
    left: 90%;
    width: 20px;
}

.icon-div {
    width: 30px;
    height: 50px;
    background-color: white;
    padding-top: 10px;
}

.card-title {
    text-align: left;
    margin-left: 10px;
}

.detail_link {
    color: black;
}

.detail_link:hover {
    text-decoration: none;
}

.volunteer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.btn-light {
    border-color: #212529;
}

.profile_round_img {
    border-radius: 50%;
}

@media only screen and (min-width: 767.9px) {
    .left_bar {
        max-width: 20px !important;
        padding-left: 0%;
    }

    .vertical_bar {
        width: 3px;
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
        float: left;
        z-index: 99;
        margin-left: 5px;
    }

    .circle {
        float: left;
        display: flex;
        width: 15px;
        height: 15px;
        border: 1px solid black;
        border-radius: 50%;
        z-index: 100;
    }

    .left_bar .active {
        background-color: #27a884;
        border: #27a884;
    }

    .hb1 {
        height: 90%;
    }

    .hb2 {
        height: 40%;
        padding-top: 40%;
    }
}

@media only screen and (max-width: 767.9px) {
    .left_bar {
        display: none;
    }
}

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');



.area {
    /* background: #4e54c8;   */
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    /* height:100vh; */
}

.home_container {
    margin-top: 0;
    padding-top: 20px;
    /* padding-bottom: 10rem; */
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -99;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: var(--over-lay);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.sm-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
    text-align: center;
}

.sm-circle svg {
    width: 60px !important;
    height: 60px !important;
}

.recent-work-img {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    object-fit: fill;
    /* z-index: -100; */
    opacity: 1;
    border: 0px !important;
}

.round-img {
    border-radius: 50% !important;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.work-img-parent:hover .recent-work-img {
    opacity: 0.2;
    /* background-color: #212529; */
}

.pd-25 {
    padding-top: 25%;
}

.pd-35 {
    padding-top: 35%;
}

.pd-10 {
    padding-top: 10%;
}

.pd-10px {
    padding-top: 10px;
}

.pb-10px {
    padding-bottom: 10px;
}

.work-img-parent:hover .middle,
.work-img-parent:hover .text-overlay {
    opacity: 1;
    width: 100%;
    height: 100%;
    background-color: var(--deeper-lay);
    border-radius: 10%;
}

.middle .skill,
.text-overlay .skill {
    border: 1px solid orange;
    border-radius: 3rem;
    width: 120px;
    background-color: orange;
    color: white;
    display: inline-block;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.skill-set svg {
    width: 60px !important;
    height: 60px !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--icon-color);
}
.report-skills{
    color: rgb(112, 112, 202) !important;
}

.skill-text {
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.text-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* -ms-transform: translate(-50%, -50%); */
    text-align: center;
}

.portfolio-img {
    width: 100%;
    height: 320px;
    object-fit: fill;
    overflow: hidden;
}

.project-row {
    margin-bottom: 5rem;
}

.capital {
    text-transform: capitalize;
}

.pd-10px .btn-group {
    margin-right: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.info_list {
    text-align: center !important;
}

.info_list a {
    color: black;
    text-decoration: none;
}

.info_list a:hover {
    color: var(--icon-color);
}